@use './styles/styles-palette';
@use '@ngneat/hot-toast/src/styles/styles.scss';

//
@tailwind base;
@tailwind components;
@tailwind utilities;
//

@import url('https://cdn.projectatlas.app/content/static/fonts/inter/v1/inter.css');
@import url('https://cdn.projectatlas.app/content/static/fonts/materialicon/v1/materialicon.css');

/* You can add global styles to this file, and also import other style files */
html,
body {
	width: 100%;
	height: 100%;
	overflow: hidden;
	overflow-y: auto;

	font-family: 'Inter', sans-serif;
	font-size: 1em;

	//background-color: #fff;
	@apply bg-yuno-blue-gray-100 text-yuno-blue-gray-800;
}

mgl-map {
	.maplibregl-ctrl-attrib {
		display: none !important;
	}
}

.atlwdg-trigger {
	z-index: 999;
}

form {
	@apply flex flex-col;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 0.25rem;
}

h1 {
	@apply text-2xl font-bold;
	margin-bottom: 0.5rem;
}

h2 {
	@apply text-xl font-bold;
}

h3 {
	@apply text-lg font-semibold;
}

h4 {
	@apply text-base font-semibold;
}

h5 {
	@apply text-sm font-semibold;
}

h6 {
	@apply text-xs font-semibold;
}

p {
	@apply mb-4 leading-6;
}

a {
	@apply cursor-pointer text-yuno-blue hover:text-yuno-blue-hover;
}

.ui {
	&.divider {
		margin-top: 2.5rem;
		margin-bottom: 2.5rem;
		border-top-width: 0px;
		border-bottom-width: 2px;

		&.small {
			margin-top: 1.25rem;
			margin-bottom: 1.25rem;
		}
	}
}

/* Firefox Scrollbar based on new CSS module */
* {
	scrollbar-width: thin;
	scrollbar-color: rgb(156, 163, 175) rgb(229, 231, 235);
}

/* Webkit scrollbar Safari/Chrome */
* {
	&::-webkit-scrollbar {
		@apply block h-4 w-1.5 bg-gray-200;
		// display: none;
	}

	&::-webkit-scrollbar-thumb {
		@apply rounded bg-gray-400 shadow;
	}
}

*:focus {
	outline: 0 !important;
}

// TODO: HOT-TOAST FIX
@keyframes hotToastEnterAnimationNegative {
	0% {
		opacity: 0.5;
		transform: translate3d(0, -80px, 0) scale(0.6);
	}

	100% {
		opacity: 1;
		transform: translate3d(0, 0, 0) scale(1);
	}
}

@keyframes hotToastEnterAnimationPositive {
	0% {
		opacity: 0.5;
		transform: translate3d(0, 80px, 0) scale(0.6);
	}

	100% {
		opacity: 1;
		transform: translate3d(0, 0, 0) scale(1);
	}
}

@keyframes hotToastExitAnimationPositive {
	0% {
		opacity: 1;
		transform: translate3d(0, 0, -1px) scale(1);
	}

	100% {
		opacity: 0;
		transform: translate3d(0, 130px, -1px) scale(0.5);
	}
}

@keyframes hotToastExitAnimationNegative {
	0% {
		opacity: 1;
		transform: translate3d(0, 0, -1px) scale(1);
	}

	100% {
		opacity: 0;
		transform: translate3d(0, -130px, -1px) scale(0.5);
	}
}

@layer components {
	.no-animation {
		transition: none !important;
		animation-delay: 0ms !important;
		animation-duration: 0ms !important;

		* {
			transition: none !important;
			animation-delay: 0ms !important;
			animation-duration: 0ms !important;
		}
	}

	.editor-component-container {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		height: 100%;
		width: 100%;
		pointer-events: all;
		z-index: 999;
	}

	.bg-stripes-gray {
		background-color: #e0e9f1;
		background-image: linear-gradient(
			135deg,
			#d8e0e9 10%,
			#0000 0,
			#0000 50%,
			#d8e0e9 0,
			#d8e0e9 60%,
			#0000 0,
			#0000
		);
		background-size: 7px 7px;
	}

	.table-container {
		width: 100%;
		display: block;
		max-width: 72rem;
	}

	.sidemenu-container-list {
		pointer-events: all;
	}
}

.cdk-overlay-pane.pointer-events-none {
	pointer-events: none;
}
