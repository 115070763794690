div,
label {
	.invalid-message {
		@apply absolute left-0 right-0 top-full z-50 flex w-full justify-start text-sm text-red-600;
		@apply mt-1 rounded-lg border-2 border-red-300 bg-red-100 bg-opacity-95 px-3 py-1;

		&.top {
			@apply bottom-full top-auto mb-1;
		}

		&.relative {
			position: relative;
			top: 0;
		}
	}

	.invalid-message-template {
		@apply z-50 flex w-full justify-start text-sm text-red-600;
		@apply rounded-lg border-2 border-red-300 bg-red-100 bg-opacity-95 px-3 py-1;
	}

	.invalid-message-wrapper:not(:empty) + .fallback-errors {
		display: none;
	}

	&.yuno-form {
		@apply relative flex h-full cursor-pointer flex-col;

		&.row {
			flex-direction: row;
		}

		&.toggle {
			@apply flex;
		}

		&.items-center {
			align-items: center;
		}

		span:not(.invalid-message span) {
			@apply mb-1 inline-flex flex-1 flex-col items-start justify-end text-sm font-semibold text-inherit;

			&.table-btn-text {
				font-weight: normal;
			}
		}

		.input-div,
		input:not([type='checkbox'], [type='radio'], [type='range']),
		select,
		textarea {
			@apply block w-full transform rounded-lg border-2
			border-gray-200 bg-gray-50 px-5
			py-3 pr-10 placeholder-gray-400
			shadow-sm transition duration-500 ease-in-out
			focus:border-transparent focus:outline-none focus:ring-2
			focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-400;

			&.ng-invalid.ng-dirty {
				@apply border-2  border-red-300 ring-red-400 focus:ring-offset-red-200;
			}

			&.browse {
				@apply pr-24;
			}

			&:disabled {
				@apply select-none border-gray-400 bg-gray-200;
			}
		}

		input[type='range'] {
			width: 100%;
			accent-color: rgb(14, 165, 233);
		}

		input[type='number'] {
			@apply pr-2;
		}

		input[type='radio'] {
			--radio-size: 20px;
			// same as bg-gray-700
			--form-control-checked-color: rgb(55, 65, 81);
			// same as text-bg-500
			--form-control-color: rgb(59, 130, 246);

			/* Add if not using autoprefixer */
			-webkit-appearance: none;
			appearance: none;
			/* For iOS < 15 to remove gradient background */
			background-color: transparent;
			/* Not removed via appearance */
			margin: 0;

			width: var(--radio-size);
			height: var(--radio-size);
			color: var(--form-control-color);

			@apply rounded-full border-2 border-gray-300 bg-gray-50;

			display: inline-grid;
			place-content: center;

			& + span {
				@apply ml-4 cursor-pointer text-sm font-semibold text-inherit;
			}

			&::before {
				content: '';
				display: block;
				width: calc(var(--radio-size) - 10px);
				height: calc(var(--radio-size) - 10px);
				border-radius: 50%;
				background-color: transparent;
				cursor: pointer;
			}

			&:checked::before {
				background-color: var(--form-control-checked-color);
			}

			&:hover {
				@apply border-2 border-gray-300 bg-gray-50;
			}

			&:focus {
				@apply border-transparent bg-gray-50 outline-none ring-2 ring-white ring-offset-2 ring-offset-gray-400;
			}

			&.white-form {
				@apply bg-white;
				&:hover {
					@apply bg-white;
				}

				&:focus {
					@apply bg-white;
				}
			}
		}

		// TOGGLE
		input[type='checkbox'] {
			&:disabled {
				@apply border-gray-500 bg-gray-400;
			}

			&:not(.toggle) {
				$check: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 14.152 10.7' stroke='currentColor'%3E%3Cpath stroke-width='2' d='M516.25,215.533l4.241,4.061L529,211' transform='translate(-515.559 -210.296)' /%3e%3c/svg%3e");

				position: absolute;
				z-index: -1000;
				left: -1000px;
				overflow: hidden;
				clip: rect(0 0 0 0);
				height: 1px;
				width: 1px;
				margin: -1px;
				padding: 0;
				border: 0;
				cursor: pointer;

				& + span {
					// CHECKBOX REPLACEMENT
					&::before {
						content: '';
						display: inline-block;

						--checker-size: 20px;
						height: var(--checker-size);
						width: var(--checker-size);
						margin-bottom: 3px;
						margin-right: 12px;

						vertical-align: middle;

						@apply border-2 border-gray-300 bg-gray-50;
						border-radius: 4px;

						// @apply transition duration-500 ease-in-out;

						background-image: none;
						background-repeat: no-repeat;
						background-position: center;
						background-size: 12px 9.03px;

						cursor: pointer;

						&:hover {
							opacity: 0.63;
						}
					}
				}

				&:focus + span {
					&::before {
						@apply border-transparent outline-none ring-2 ring-white ring-offset-2 ring-offset-gray-400;
					}
				}

				&:checked + span {
					&::before {
						background-image: $check;
					}
				}

				&.white-form {
					& + span {
						// CHECKBOX REPLACEMENT
						&::before {
							@apply bg-white;
						}
					}
				}
			}

			// TOGGLE CHECKBOX
			&.toggle {
				@apply mt-1 h-6 w-14 rounded-3xl border-2 border-gray-50 bg-gray-300
				focus:border-transparent focus:outline-none focus:ring-2
				focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-400;

				appearance: none;
				-webkit-appearance: none;
				-moz-appearance: none;
				transition: transform 0.07s;
				cursor: pointer;

				&:after {
					@apply h-[1.06rem] w-[1.06rem] rounded-full bg-gray-50 md:h-[1.125rem] md:w-[1.125rem];
					margin: 0.063rem;
					content: '';
					display: inline-block;
					transition: transform 0.07s linear;
				}

				&:checked {
					background-color: #4d5b74;
					background-image: none;
				}

				&:checked::after {
					@apply translate-x-8;
				}

				&:hover {
					transform: scale(1.07);
				}

				&:active {
					transform: scale(1);
				}

				&:disabled {
					@apply transform-none border-gray-50 bg-gray-400 transition-none;
					pointer-events: none;

					&::after {
						background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%234D5B74'%3E%3Cpath fill-rule='evenodd' d='M5 9V7a5 5 0 0 1 10 0v2a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2zm8-2v2H7V7a3 3 0 0 1 6 0z' clip-rule='evenodd'/%3E%3C/svg%3E");
						background-size: 80%;
						background-repeat: no-repeat;
						cursor: default;
						background-position: center;
					}
				}
			}
		}
	}

	&.yuno-form.white-form {
		input[type='radio'] {
			@apply bg-white;
			&:hover {
				@apply bg-white;
			}

			&:focus {
				@apply bg-white;
			}
		}

		input[type='checkbox'] {
			&:not(.toggle) {
				& + span {
					// CHECKBOX REPLACEMENT
					&::before {
						@apply bg-white;
					}
				}
			}
		}
	}
}
