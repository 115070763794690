@use '@angular/material' as mat;
@use 'sass:map';

$fontConfig: (
	display-4: mat.m2-define-typography-level(112px, 112px, 300, 'Inter', -0.0134em),
	display-3: mat.m2-define-typography-level(56px, 56px, 400, 'Inter', -0.0089em),
	display-2: mat.m2-define-typography-level(45px, 48px, 400, 'Inter', 0em),
	display-1: mat.m2-define-typography-level(34px, 40px, 400, 'Inter', 0.0074em),
	headline: mat.m2-define-typography-level(24px, 32px, 400, 'Inter', 0em),
	title: mat.m2-define-typography-level(20px, 32px, 500, 'Inter', 0.0075em),
	subheading-2: mat.m2-define-typography-level(16px, 28px, 400, 'Inter', 0.0094em),
	subheading-1: mat.m2-define-typography-level(15px, 24px, 500, 'Inter', 0.0067em),
	body-2: mat.m2-define-typography-level(14px, 24px, 500, 'Inter', 0.0179em),
	body-1: mat.m2-define-typography-level(14px, 20px, 400, 'Inter', 0.0179em),
	button: mat.m2-define-typography-level(14px, 14px, 500, 'Inter', 0.0893em),
	caption: mat.m2-define-typography-level(12px, 20px, 400, 'Inter', 0.0333em),
	input: mat.m2-define-typography-level(inherit, 1.125, 400, 'Inter', 1.5px)
);

@include mat.all-component-typographies($fontConfig);
@include mat.elevation-classes();
@include mat.app-background();

$primary: (
	Main: #3498db,
	Light: #c2e0f4,
	Dark: #3498db,
	contrast: (
		Main: #fff,
		Light: #000,
		Dark: #fff
	)
);
$accent: (
	Main: #4b5563,
	Light: #c9ccd0,
	Dark: #333b47,
	contrast: (
		Main: #fff,
		Light: #000,
		Dark: #fff
	)
);
$warn: (
	Main: #c0392b,
	Light: #ecc4bf,
	Dark: #aa241a,
	contrast: (
		Main: #fff,
		Light: #fff,
		Dark: #fff
	)
);

$primary: mat.m2-define-palette($primary, Main, Light, Dark);
$accent: mat.m2-define-palette($accent, Main, Light, Dark);
$warn: mat.m2-define-palette($warn, Main, Light, Dark);

$custom-theme: mat.m2-define-light-theme(
	(
		color: (
			primary: $primary,
			accent: $accent,
			warn: $warn
		)
	)
);

@include mat.all-component-themes($custom-theme);

$custom-palette: (
	warning-secondary: #f1c40f,
	success: hsl(140, 40%, 50%),
	secondary: black,
	contrast: (
		warning-secondary: white,
		success: white,
		secondary: white
	)
);

$custom-palette-keys: map.remove($custom-palette, contrast);

@each $key in map.keys($custom-palette-keys) {
	.mat-button,
	.mat-stroked-button,
	.mat-raised-button,
	.mat-flat-button,
	.mat-fab,
	.mat-mini-fab,
	.mat-icon-button {
		&.mat-#{$key} {
			background-color: map.get($custom-palette, $key);
			color: map.get($custom-palette, contrast, $key);
		}
	}
}

.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
	background-color: map.get($custom-palette-keys, success);
}
