.no-pointer-events {
	pointer-events: none !important;
}

// Used by KRPANO, ThreeJS
.inherit-cursors {
	* {
		cursor: inherit !important;
	}
}
